import React from "react";
import Layout from "../../../components/Layout";

const RefundAndCancellationPage = () => {
  const pageTitle = "Refund and Cancellation Policy | Unschool";
  const pageDescription =
    "For Refund Request the user may place a request for the same on the platform of the Company, the Representative of the Company shall reach out to the User on the Registered number";

  return (
    <Layout title={pageTitle} description={pageDescription}>
      <div className="policies-container">
        <h2 className="title">REFUND AND CANCELLATION POLICY</h2>
        <ul>
          <li>
            <strong>RETURN</strong>
          </li>
        </ul>
        <p>
          {" "}
          The Company disclaims all liabilities and ascertains that the Company
          under no circumstances shall the Company entertain any request for a
          Return of any such request placed on{" "}
          <strong>
            <em>www.unschool.in.</em>
          </strong>
        </p>
        <ul>
          <li>
            <strong>EXCHANGE</strong>
          </li>
        </ul>
        <p>
          {" "}
          <strong>
            <em>&#39;Exchange&#39;</em>
          </strong>{" "}
          is the action or process of Exchanging of the services provided by the
          Companyfor any such other services provided by the Company. User can
          request for an Exchange of Course availed by the User on the platform
          with another course available on the platform after placing a request
          for the Course but before accessing and availing the course.
        </p>
        <p>
          {" "}
          An intimation shall be provided by the Company to the User seeking
          either &quot;Exchange” and the User shall receive a confirmation
          regarding the replacement.
        </p>
        <p>
          {" "}
          <strong>POINTS TO BE NOTED:</strong>
        </p>
        <ul>
          <li>
            The user can request for an exchange before accessing the course
            online on the platform of the User.
          </li>
        </ul>
        <ul>
          <li>
            If the Company disagrees an exchange request due to non-technical
            issue, User can file a dispute.
          </li>
        </ul>
        <ul>
          <li>
            We encourage the User to review the course and its subjective nature
            before making the request to avail such services.
          </li>
        </ul>
        <ul>
          <li>
            User needs to raise the exchange request within 7 days from availing
            the course online.
          </li>
        </ul>
        <p>
          <strong>REFUND PROCESS</strong>
        </p>

        <ol>
          <li>
            For Refund Request the user may place a request for the same on the
            platform of the Company, the Representative of the Company shall
            reach out to the User on the Registered number provided by the User
            on the Platform.
          </li>
          <li>
            The Refund request shall be entertained and process if the Company
            has failed to provide the course availed for by the User within 7
            days from generating the Invoice and receiving the payment.
          </li>
          <li>
            In no other circumstance, the Company shall process a refund
            request.
          </li>
          <li>
            The Company be at the sole discretion to refund to the User and all
            refunds shall be processed after such statutory deductions.
          </li>
          <li>
            All refunds shall be credited to the source of the payment received
            by the Company from the User.
          </li>
          <li>
            In certain situations, the refunds shall reflect in the payment
            source in 3-5 working days and the company disclaims all liabilities
            for the same.
          </li>
        </ol>
      </div>
    </Layout>
  );
};

export default RefundAndCancellationPage;
