import React, { useEffect, useState } from "react";
import "./all.scss";
import "../styles/overwrite.scss";
import Footer from "../components/Footer";
import Navbar from "./Navbar";
import PropTypes from "prop-types";
import Seo from "./Seo";
import { useStore } from "../utils/redux/store";
import { after, conforms, isEqual, throttle } from "lodash";
import { saveState } from "../helpers/redux";
import { useSelector } from "react-redux";
import { useLazyQuery } from "@apollo/client";
import { GET_CART_DETAILS } from "../../graphql/queries/cart";
import { bindActionCreators } from "redux";
import {
  cartDetailsActions,
  userActions,
  userCoursesActions,
  userPlansActions,
} from "../utils/redux/actions";
import { useDispatch } from "react-redux";
import PageLoader from "./PageLoader";
import { initializeApollo } from "../../apollo-client";
import { logger } from "../helpers/logger";
import { isUserAuthenticated, getFormattedLocationList } from "../helpers/auth";
import { GET_USER_DETAILS } from "../../graphql/queries/user";
import {
  GET_USER_SERIES,
  GET_USER_COURSE,
  GET_USER_PLANS,
} from "../../graphql/queries/courses";
import { GET_LOCATION_LIST } from "../../graphql/queries/location";

const SmoothScroll =
  typeof window !== `undefined` ? require("smooth-scroll") : null;

const TemplateWrapper = (props) => {
  let {
    children,
    transparentNavbar,
    title,
    description,
    keywords,
    robots,
    httpEquiv,
    language,
    revisitAfter,
    author,
    schemaMarkup,
    footer,
  } = props;

  const store = useStore();

  const dispatch = useDispatch();

  store.subscribe(
    throttle(() => {
      saveState(store.getState());
    }, 500)
  );

  const [loadingPageDetails, setLoadingPageDetails] = useState(true);
  const cartDetails = useSelector((state) => state.cartDetails);

  const { setCartDetails } = bindActionCreators(cartDetailsActions, dispatch);
  const { userUpdateAction, userLoginAction, userResetAction } =
    bindActionCreators(userActions, dispatch);
  const { setUserCourseDetails, setUserSeriesCourseDetails } =
    bindActionCreators(userCoursesActions, dispatch);

  const { setUserPlans } = bindActionCreators(userPlansActions, dispatch);

  const getCartDetails = async () => {
    const apolloClient = initializeApollo({
      initialState: null,
      ctx: null,
    });
    try {
      const { data } = await apolloClient.query({
        query: GET_CART_DETAILS,
        variables: {
          uuid: cartDetails?.uuid,
        },
      });
      if (data?.cartDetail) {
        const fetchedCartDetails = data?.cartDetail;
        if (!cartDetails || !isEqual(fetchedCartDetails, cartDetails)) {
          setCartDetails(fetchedCartDetails);
        }
      }
    } catch (error) {
      const gqlError = error.graphQLErrors[0];
      if (gqlError) {
        logger.warn(gqlError);
      }
    }
  };

  const getAllPlans = async () => {
    const apolloClient = initializeApollo({
      initialState: null,
      ctx: null,
    });
    try {
      const { data: userPlansData } = await apolloClient.query({
        query: GET_USER_PLANS,
        fetchPolicy: "network-only",
      });

      if (userPlansData?.plans) {
        setUserPlans(userPlansData?.plans);
      }
    } catch (error) {
      const gqlError = error.graphQLErrors[0];
      if (gqlError) {
        logger.warn(gqlError);
      }
    }
  };

  const checkIfUserIsSignedIn = async () => {
    const apolloClient = initializeApollo({
      initialState: null,
      ctx: null,
    });
    try {
      const { data: userData } = await apolloClient.query({
        query: GET_USER_DETAILS,
      });
      if (userData?.currentUser) {
        setLoadingPageDetails(false);
        try {
          const { data: locationListData } = await apolloClient.query({
            query: GET_LOCATION_LIST,
          });
          if (locationListData?.LocationList) {
            const {
              email,
              firstName,
              lastName,
              mobileNumber,
              college,
              profilePicture,
              location,
              activeCart,
              getChargebeeId,
              getSubscriptionDetails,
              isUserSubscribed,
            } = userData.currentUser;
            userLoginAction();
            if (firstName && lastName && mobileNumber && location) {
              userUpdateAction({
                firstName,
                lastName,
                mobileNumber,
                college,
                profilePicture,
                email,
                location,
                locationList: getFormattedLocationList(
                  locationListData?.LocationList[0]?.children
                ),
                activeCart,
                getChargebeeId,
                getSubscriptionDetails,
                isUserSubscribed,
              });
              setCartDetails(activeCart);
            }
            try {
              const { data: userSeriesData } = await apolloClient.query({
                query: GET_USER_SERIES,
              });
              if (userSeriesData?.seriesList) {
                setUserSeriesCourseDetails(userSeriesData?.seriesList?.data);
              }
            } catch (error) {
              const gqlError = error.graphQLErrors[0];
              if (gqlError) {
                logger.warn(gqlError);
              }
            }
            try {
              const { data: userCourseData } = await apolloClient.query({
                query: GET_USER_COURSE,
              });
              if (userCourseData?.courses) {
                setUserCourseDetails(userCourseData?.courses?.data);
              }
            } catch (error) {
              const gqlError = error.graphQLErrors[0];
              if (gqlError) {
                logger.warn(gqlError);
              }
            }
            getAllPlans();
          }
        } catch (error) {
          const gqlError = error.graphQLErrors[0];
          if (gqlError) {
            logger.print(gqlError.message);
          }
        }
      }
    } catch (error) {
      setLoadingPageDetails(false);
      const gqlError = error.graphQLErrors[0];
      if (gqlError) {
        userResetAction();
        logger.warn(gqlError);
      }
    }
  };

  useEffect(() => {
    if (cartDetails?.uuid) {
      getCartDetails();
    }
    if (isUserAuthenticated()) {
      checkIfUserIsSignedIn();
    } else {
      getAllPlans();
      userResetAction();
      setLoadingPageDetails(false);
    }
  }, []);

  const navbarData = [
    {
      name: "hello",
    },
  ];

  React.useEffect(() => {
    if (window && SmoothScroll) {
      var scroll = new SmoothScroll("a[data-scroll]");
    }
    // if (window) {
    //   window.dataLayer = window.dataLayer || [];
    //   function gtag() {
    //     window.dataLayer.push(arguments);
    //   }
    //   gtag("js", new Date());
    //
    //   gtag("config", "UA-134257618-1");
    // }
  }, []);

  React.useEffect(() => {
    setTimeout(() => {
      var truepush = window.truepush || [];
      truepush.push(function () {
        truepush.Init(
          {
            id: "5fa109661193e81485015fdb",
          },
          function (error) {
            if (error) console.log(error);
          }
        );
      });
    }, 10000);
  }, []);

  if (loadingPageDetails) return <PageLoader />;

  return (
    <div>
      <Seo
        title={title}
        description={description}
        keywords={keywords}
        robots={robots}
        httpEquiv={httpEquiv}
        language={language}
        revisitAfter={revisitAfter}
        author={author}
        schemaMarkup={schemaMarkup}
      />
      <Navbar transparent={transparentNavbar} navbarCategory={navbarData} />
      <div className={"dark-body-bg-padding"}>
        <div>{children}</div>
      </div>
      {!footer && <Footer />}
    </div>
  );
};

export default TemplateWrapper;
